// app/javascript/controllers/menu_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mainMenu", "searchInput", "menuItem", "backButton", "searchResults", "footer"]
  static values = {
    menuItems: Array
  }

  connect() {
    console.log("Menu controller connected")

    // Parse menu data if not already available through values
    if (!this.hasMenuItemsValue && this.element.dataset.menuItems) {
      try {
        this.menuItemsValue = JSON.parse(this.element.dataset.menuItems)
      } catch (e) {
        console.error('Error parsing menu data:', e)
      }
    } else if (this.hasMenuItemsValue) {
    } else {
      console.warn("No menu items data found")
    }

    // Set up initial state
    this.selectCurrentContext()

    // Register Turbo event listeners
    this.setupTurboEventListeners()
  }

  disconnect() {
    // Clean up Turbo event listeners to prevent memory leaks
    this.cleanupTurboEventListeners()
  }

  // Turbo event handling methods
  setupTurboEventListeners() {
    // Handle Turbo page changes - save as properties to clean up later
    this.handleBeforeCache = this.handleBeforeCache.bind(this)
    this.handleBeforeRender = this.handleBeforeRender.bind(this)
    this.handleRender = this.handleRender.bind(this)

    document.addEventListener("turbo:before-cache", this.handleBeforeCache)
    document.addEventListener("turbo:before-render", this.handleBeforeRender)
    document.addEventListener("turbo:render", this.handleRender)
  }

  cleanupTurboEventListeners() {
    document.removeEventListener("turbo:before-cache", this.handleBeforeCache)
    document.removeEventListener("turbo:before-render", this.handleBeforeRender)
    document.removeEventListener("turbo:render", this.handleRender)
  }

  handleBeforeCache() {
    // Reset menu state before page is cached
    this.hideAllMenus()
    this.mainMenuTarget.classList.remove('hidden')

    // Clear any search input
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.value = ""
    }
  }

  handleBeforeRender(event) {
    // Store current menu state if needed before rendering new page
    const currentMenu = this.element.querySelector('.sub-menu:not(.hidden)')
    if (currentMenu) {
      sessionStorage.setItem('lastActiveMenu', currentMenu.classList[1])
    }
  }

  handleRender(event) {
    // Restore menu state after page render if needed
    // Or re-select the current context based on new URL
    this.selectCurrentContext()
  }

  // Handle search input
  search(event) {
    const searchTerm = event.target.value.toLowerCase()

    if (!searchTerm) {
      this.resetSearch()
      return
    }

    this.performSearch(searchTerm)
  }

  // Reset search and go back to main menu
  resetSearch() {
    this.hideAllMenus()
    this.mainMenuTarget.classList.remove('hidden')

    if (this.hasFooterTarget) {
      this.footerTarget.classList.remove('hidden')
    }

    // Clear search input
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.value = ''
    }
  }

  // Navigate to a submenu
  navigateToMenu(event) {
    event.preventDefault()
    const targetMenu = event.currentTarget.dataset.menu
    if (!targetMenu) return

    this.navigateToSubmenu(targetMenu)
  }

  // Go back to previous menu
  navigateBack(event) {
    const prevMenu = event.currentTarget.dataset.prev
    if (!prevMenu) return

    this.hideAllMenus()

    // Find the target menu
    const targetMenu = this.element.querySelector(`.sub-menu.${prevMenu}`)
    if (targetMenu) {
      targetMenu.classList.remove('hidden')
    }

    if (this.hasFooterTarget) {
      this.footerTarget.classList.remove('hidden')
    }
  }

  // Find and display the current section based on URL
  selectCurrentContext() {
    if (!this.hasMenuItemsValue) return

    const pathname = window.location.pathname
    const currentItem = this.menuItemsValue.find(m =>
        m.menu_items.some(mi => mi.path === pathname)
    )

    if (currentItem) {
      if (currentItem.prev) {
        // Item is in a submenu
        const parentItem = this.menuItemsValue.find(m => m.code === currentItem.prev)

        if (parentItem) {
          // Highlight parent menu item
          const parentMenuItem = this.element.querySelector(`.menu_${parentItem.code}`)
          if (parentMenuItem) {
            parentMenuItem.classList.add('active', 'bg-accent-100')
          }

          // Navigate to parent menu
          this.navigateToSubmenu(parentItem.code)
        }
      } else {
        // Item is a main menu
        const menuItem = this.element.querySelector(`.menu_${currentItem.code}`)
        if (menuItem) {
          menuItem.classList.add('active', 'bg-accent-100')
          this.navigateToSubmenu(currentItem.code)
        }
      }
    } else {
      // If no matching menu item is found, show the main menu
      this.hideAllMenus()
      this.mainMenuTarget.classList.remove('hidden')

      if (this.hasFooterTarget) {
        this.footerTarget.classList.remove('hidden')
      }
    }
  }

  // Private methods
  navigateToSubmenu(menuCode) {
    this.hideAllMenus()

    const targetMenu = this.element.querySelector(`.sub-menu.${menuCode}`)
    if (targetMenu) {
      targetMenu.classList.remove('hidden')
    }

    if (this.hasFooterTarget && !menuCode.includes('search-results')) {
      this.footerTarget.classList.remove('hidden')
    }
  }

  hideAllMenus() {
    this.element.querySelectorAll('.sub-menu').forEach(menu => {
      menu.classList.add('hidden')
    })

    if (this.hasFooterTarget) {
      this.footerTarget.classList.add('hidden')
    }
  }

  performSearch(searchTerm) {
    if (!this.hasSearchResultsTarget) {
      console.error("Search results target not found")
      return
    }

    // Clear previous results
    const resultsList = this.searchResultsTarget.querySelector('ul')

    if (!resultsList) {
      console.error("Results list not found in search results target")
      return
    }

    resultsList.innerHTML = ''

    // Hide all menus
    this.hideAllMenus()

    // Show search results
    this.searchResultsTarget.classList.remove('hidden')

    // Check if we have menu items data
    if (!this.hasMenuItemsValue || !this.menuItemsValue.length) {
      console.error("No menu items data for search")
      const noResults = this.createFromTemplate('template-no-results')

      if (noResults) {
        noResults.textContent = "No menu data available for search"
        resultsList.appendChild(noResults)
      }
      return
    }

    let resultsFound = 0

    // Alternative search method that accesses menu data directly
    // This ensures we're getting the latest and most accurate menu structure
    const menuItems = this.menuItemsValue

    menuItems.forEach(category => {
      const categoryName = category.name || ""
      const categoryMatches = categoryName.toLowerCase().includes(searchTerm)

      // Find matching submenu items
      const categoryMenuItems = category.menu_items || []
      const matchingItems = categoryMenuItems.filter(item => {
        const itemName = item.name || ""
        const matches = itemName.toLowerCase().includes(searchTerm)
        return matches
      })

      if (categoryMatches || matchingItems.length > 0) {
        // Add category header
        const categoryHeader = this.createFromTemplate('template-category-header')
        if (categoryHeader) {
          categoryHeader.textContent = categoryName
          resultsList.appendChild(categoryHeader)
        }

        // Add category if it matches
        if (categoryMatches) {
          this.addTemplateMenuItem(resultsList, 'template-main-item', categoryName, category.code)
          resultsFound++
        }

        // Add matching submenu items
        matchingItems.forEach(item => {
          this.addTemplateMenuItem(resultsList, 'template-sub-item', item.name, item.path || category.code)
          resultsFound++
        })
      }
    })

    // Show no results message if needed
    if (resultsFound === 0) {

      const noResults = this.createFromTemplate('template-no-results')
      if (noResults) {
        resultsList.appendChild(noResults)
      }
    }
  }

// Modify only the specific methods that handle creating menu items from templates

  addTemplateMenuItem(resultsList, templateId, itemName, destination) {

    // Get the template
    const template = document.getElementById(templateId);
    if (!template) {
      console.error(`Template not found: ${templateId}`);
      return;
    }

    // Clone the template
    const menuItem = template.cloneNode(true);
    menuItem.id = ''; // Remove the template ID

    // Make it visible
    menuItem.classList.remove('hidden');

    // Set text content - log each step
    const textElement = menuItem.querySelector('.menu-text');
    if (textElement) {
      textElement.textContent = itemName;
    } else {
      console.error(`Text element not found in template: ${templateId}`);
    }

    // Set up click handler
    const link = menuItem.querySelector('a');
    if (link) {
      if (typeof destination === 'string' && !destination.startsWith('/')) {
        // It's a menu code, navigate to submenu
        link.setAttribute('data-action', 'menu#navigateToMenu');
        link.setAttribute('data-menu', destination);
      } else {
        link.href = destination;
      }
    } else {
      console.error(`Link not found in template: ${templateId}`);
    }

    resultsList.appendChild(menuItem);

  }

  createFromTemplate(templateId) {
    // This function should just be a helper for single-element templates
    const template = document.getElementById(templateId);
    if (!template) {
      console.error(`Template not found: ${templateId}`);
      return null;
    }

    const clone = template.cloneNode(true);
    clone.id = ''; // Remove the template ID
    clone.classList.remove('hidden'); // Make sure it's visible
    return clone;
  }
}